
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    labelOnly: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      // set width for label
      type: String,
      default: "w-20 sm:w-32",
    },
    value: {
      type: String as PropType<any>,
      default: "",
    },
    colon: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nonText: {
      type: Boolean,
      default: false,
    },
    margin: {
      // margin bottom
      type: String,
      default: "mb-3",
    },
    textSize: {
      // font size / text size
      type: String,
      default: "text-sm",
    },
  },
  setup() {
    return {};
  },
});
